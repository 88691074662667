import cn from "./Pause.module.css";

export const PauseMenu = () => {
  return (
    <div className={cn.container}>
      {/* <NavLink to="/">city</NavLink>
      <NavLink to="dust">dust</NavLink>
      <NavLink to="pharma">pharma</NavLink> */}
    </div>
  );
};
